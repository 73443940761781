import type { AxiosResponse } from "axios";
import { PATHS } from "~constants";
import { localCache } from "~utils/cacheHelper";
import { logOut, setCredentials } from "~utils/funcHelper";
import request from "./request";
import axios from "axios";

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

function addSubscriber(callback: (token: string) => void) {
  refreshSubscribers.push(callback);
}

// Hàm gọi tất cả các callback trong mảng chờ khi token đã được làm mới
function onRefreshed(newToken: string) {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
  isRefreshing = false;
}

const refreshToken = async () => {
  try {
    const { data } = await axios.post(request.defaults.baseURL + "/auth/refresh", {
      refreshToken: localCache.get("refreshToken"),
    });
    const { token, refreshToken } = data;
    setCredentials(token, refreshToken);
    request.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    onRefreshed(token);
  } catch {
    logOut();
    window.location.href = "/login";
  }
};

const responseHandler = {
  success: (response: AxiosResponse) => {
    return response.data;
  },
  error: async (error: any) => {
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== PATHS.LOGIN &&
      !originalRequest._retry
    ) {
      error.config._retry = true;
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;
        await refreshToken();
        return request(originalRequest);
      } else {
        return new Promise((resolve) => {
          addSubscriber((newToken) => {
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(request(originalRequest));
          });
        });
      }
    }
    return Promise.reject(error.response?.data);
  },
};

export default responseHandler;
